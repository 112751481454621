/* Added For Pre-Order Enhancement */
.product--preorder {
  &.product--not-shoppable {
    .product {
      &__bag-bar--sticky {
        li {
          &.preorder-noshop {
            @include swap_direction(padding, 10px);
            height: auto;
          }
        }
      }
    }
  }
}

.site-bag {
  &__contents {
    .cart_overlay {
      &__preorderhead {
        h3 {
          @include swap_direction(padding, 15px);
          font-size: 24px;
        }
      }
    }
  }
}

.viewcart {
  .preorder-panel {
    &__order-summary {
      margin-bottom: 20px;
    }
  }
}

.checkout__content,
.checkout__sidebar {
  .preorder-only {
    @include swap_direction(padding, 0);
    @include swap_direction(margin, 0);
    border-bottom: none;
  }
}
/* Side Panel preorder */
.checkout__sidebar {
  .preorder-panel {
    &.side-panel {
      .cart-item__total {
        width: auto;
        padding-right: 0;
        margin-left: 55.33333%;
        line-height: 20px;
      }
      .cart-item__pretext,
      .qty {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 0);
        width: 50%;
        float: #{$rdirection};
        clear: #{$rdirection};
      }
    }
  }
}

.grid--mpp__item {
  .product--preorder {
    .product--teaser {
      &__msg-preorder {
        min-height: 55px;
        .hide-shoppable-product,
        .hide-non-shoppable-product {
          line-height: 15px;
          position: relative;
          z-index: 100;
        }
      }
    }
    &.product--not-shoppable {
      .product__inventory-status {
        li {
          &.preorder-noshop {
            @include swap_direction(padding, 0 15px);
            font-size: 15px;
            height: 30px;
            line-height: 30px;
          }
        }
      }
    }
  }
}
/* Order History preorder */
.preorder-history {
  &__header {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  &__subheader {
    @include swap_direction(margin, 5px 0 10px 0);
    font-size: 14px;
  }
}

.preorder-history-container {
  .address-book-page {
    &__content {
      padding: 0;
    }
  }
  .payment-info {
    &__preorder {
      padding: 1em 0;
    }
  }
}

.preorder-bold {
  font-weight: bold;
}

.preorder-highlight {
  color: $color--red;
}

.favorites-page,
.past-purchases {
  .product__header {
    .preorder_message {
      display: none;
    }
  }
  .product__footer {
    .preorder_message {
      float: #{$rdirection};
      width: 35%;
    }
  }
}
