.pac-container {
  &.pac-logo {
    z-index: 9999;
  }
}

.order-details-page {
  .order-details-page__content {
    .product-header {
      .product {
        width: 42.66667%;
      }
      .tracking {
        margin-left: 42.66667%;
      }
      .price {
        margin-left: 60%;
      }
      .quantity {
        margin-left: 72.33333%;
      }
      .total {
        width: 11.33333%;
      }
    }
    .cart-item {
      &__price {
        margin-left: 61%;
      }
      &__qty {
        margin-left: 72.33333%;
      }
    }
  }
}

.address-overlay-shipping {
  .form_element,
  #google_locationField {
    margin-bottom: 15px;
  }
}

#search-wrapper {
  @include breakpoint($bp--large-up) {
    .search-filter__trigger {
      width: 26%;
    }
  }
}

.past-purchases {
  .products {
    width: 100%;
  }
}

.loyalty-page__content {
  padding: 0 20px;
}

.account-loyalty {
  .mac-select__account-panel__content {
    &-title p {
      font-family: $roboto-condensed-regular-font;
    }
  }
}
