/* The marketing template counts levels from 1 */
body {
  .account-nav {
    .mac-select__progress {
      &-indicator {
        #{$ldirection}: 0;
      }
      &-border {
        width: 0;
      }
      &-label {
        margin-#{$ldirection}: 0;
      }
    }
  }
  .site-header {
    .site-header__tools {
      .site-my-mac {
        &__contents {
          &-footer {
            &__loyalty {
              .mac-select__color-tier0 {
                color: $black;
              }
              &:hover {
                .mac-select__color-tier0 {
                  color: $tier0color1gr;
                }
              }
            }
            &.has-link {
              .site-my-mac__contents-footer-title {
                span.tier-1 {
                  color: $tier0color1gr;
                }
              }
            }
          }
        }
      }
    }
  }
  #loyalty__content {
    #loyalty__page__index {
      .loyalty__page__index-2col {
        margin-bottom: 0px;
        border-bottom: none;
        .loyalty__panel__benefits__title {
          .mac-select__color-tier0 {
            color: $black;
          }
        }
      }
      #loyalty__panel {
        &__points {
          .mac-select {
            &__progress {
              &-label {
                text-align: center;
              }
              &-level {
                position: absolute;
                top: 10px;
                #{$ldirection}: 12px;
                color: $color--white;
                text-align: #{$ldirection};
              }
            }
          }
        }
        &__redeem {
          padding: 20px;
          margin-bottom: 20px;
          border-top: 1px solid #c7c7c7;
          .loyalty__panel__redeem__subtitle {
            margin-bottom: 15px;
          }
          &.hidden {
            border-top: none;
          }
        }
      }
      #loyalty__panel__points {
        .mac-select__progress-level {
          .mac-select__progress-level__title {
            font-family: $roboto-condensed-bold-font;
          }
          .mac-select__progress-level__tier {
            font-family: $roboto-condensed-regular-font;
          }
        }
      }
    }
  }
  .mac-select {
    &__marketing-page__banner-content {
      top: 50%;
      #{$ldirection}: 50%;
      h6 {
        color: $color--white;
      }
      a {
        border: 1px solid $color--white;
        &:hover {
          border-bottom: 1px solid $color--white;
        }
      }
    }
    &__swatch {
      &-tier0 {
        background-color: $black;
      }
      &-tier1 {
        background-color: $tier1color1gr;
      }
      &-tier2 {
        background-color: $tier2color1gr;
      }
      &-tier3 {
        background-color: $tier3color1gr;
      }
      &-tier0,
      &-tier1,
      &-tier2,
      &-tier3 {
        background-image: none;
      }
    }
    &__color {
      &-tier0 {
        color: $tier0color1gr;
      }
      &-tier1 {
        color: $tier1color1gr;
      }
      &-tier2 {
        color: $tier2color1gr;
      }
      &-tier3 {
        color: $tier3color1gr;
      }
    }
    &__marketing-page__lover {
      .mac-select__marketing-page {
        &__header {
          display: none;
        }
        &__benefits__section {
          &-all {
            ul li {
              font-family: $roboto-condensed-bold-font;
            }
          }
          &-copy {
            &.mac-select__swatch-tier1,
            &.mac-select__swatch-tier2,
            &.mac-select__swatch-tier3,
            &.mac-select__swatch-lovertier4 {
              ul {
                li {
                  justify-content: center;
                }
              }
            }
            &.mac-select__swatch-tier4 ul li span {
              background-color: $color--gray-tier3;
            }
            &.mac-select__swatch-tier3 li span {
              background-color: $color--red-tier2;
            }
            &.mac-select__swatch-tier2 li span {
              background-color: $color--pink-tier1;
            }
            &.mac-select__swatch-tier1 ul li span {
              background-color: $color--black;
            }
          }
        }
        &__benefits {
          &__section-title {
            h3 {
              font-family: $roboto-condensed-regular-font;
            }
          }
        }
      }
    }
    &-loyalty-summary__header {
      display: block;
    }
  }
  &.section-mac-lover,
  #loyalty__page__index-bottom {
    .mac-select__marketing-page__benefits {
      .mac-select {
        &__swatch {
          &-tier1,
          &-tier2,
          &-tier3,
          &-tier4 {
            background: 0 0;
          }
        }
      }
    }
  }
  &.section-mac-lover {
    font-family: $roboto-condensed-bold-font;
    .mac-select__marketing-page__questions {
      padding-bottom: 0px;
    }
    #promo-message,
    #mac-lover-disclaimer {
      text-align: center;
      font-weight: bold;
    }
    #promo-message {
      padding-bottom: 1em;
    }
    .mac-lover-level {
      &--150 {
        .mpp-complementary-products {
          &__item:nth-of-type(1) {
            margin-#{$ldirection}: 25%;
          }
          margin-#{$ldirection}: 9%;
          width: 82%;
        }
      }
      &--300,
      &--450 {
        .grid--mpp__item {
          min-width: 100%;
        }
        .slick-slider {
          margin-left: 9%;
          width: 82%;
        }
      }
    }
    .mpp-custom {
      width: 100%;
      margin-#{$ldirection}: unset;
      margin-#{$rdirection}: unset;
      min-width: 100%;
      &__header {
        line-height: 1;
        margin: 0;
        padding: 15px 20px;
        h3 {
          color: $color--white;
          margin-top: 15px;
        }
        h4 {
          margin: 0px;
          color: $color--white;
        }
      }
      &:nth-of-type(1) {
        .mpp-custom__header {
          background-color: $tier1color1gr;
        }
        .grid--mpp {
          padding-#{$ldirection}: 0px;
        }
      }
      &:nth-of-type(2) {
        .mpp-custom__header {
          background-color: $tier2color1gr;
        }
      }
      &:nth-of-type(3) {
        .mpp-custom__header {
          background-color: $tier3color1gr;
        }
      }
      .grid--mpp {
        &__item {
          padding: 0px;
          margin-bottom: 0px;
          height: 100% !important;
          .product {
            height: auto;
            &__detail {
              height: auto;
            }
            &__sku-details {
              height: auto;
            }
            &--teaser {
              margin-bottom: 0px;
              border-top: none;
              min-height: unset;
              padding-top: 0px;
            }
            &__header {
              height: unset;
              padding-bottom: 0px;
              min-height: unset;
            }
            &__name {
              padding-#{$rdirection}: 0px;
              &-link {
                width: 100%;
                text-align: center;
              }
            }
            &__description-short {
              text-align: center;
              @include breakpoint($bp--large-up) {
                margin-#{$rdirection}: 0px;
              }
            }
            &__mini-detail {
              margin-#{$rdirection}: 0px;
              margin-bottom: 6px;
              text-align: center;
            }
            &__rating,
            &__faves--teaser {
              display: none;
            }
            &__images {
              position: relative;
              bottom: unset;
            }
            &__images-inner {
              .product__sku-image--rendered--medium {
                margin: auto;
              }
            }
            &__image-medium {
              max-width: unset;
            }
            &__footer {
              padding-top: 0.5em;
              border-top: none;
              position: relative;
            }
            &__price {
              display: none;
            }
            &__add-to-bag {
              display: none !important;
            }
            &__inventory-status {
              a {
                display: none;
              }
              .temp-out-of-stock-short__text {
                margin-top: 0px;
                position: relative;
                text-align: center;
                font-size: 1rem;
              }
            }
          }
        }
        /* 5 items */
        div.grid--mpp__item:first-child:nth-last-child(5),
        div.grid--mpp__item:first-child:nth-last-child(5) ~ div.grid--mpp__item {
          width: 20%;
          max-width: 20%;
          height: 425px !important;
        }
        /* 6 items */
        div.grid--mpp__item:first-child:nth-last-child(6),
        div.grid--mpp__item:first-child:nth-last-child(6) ~ div.grid--mpp__item {
          width: 16.5%;
          max-width: 16.5%;
          height: 390px !important;
        }
      }
    }
    .custom-color-block__text {
      width: 100%;
      margin: 10px 0 0;
      text-align: center;
      font-size: 2.5rem;
      padding: 15px 20px;
      font-family: $roboto-condensed-bold-font;
      color: $color--white;
      &:nth-of-type(1) {
        background-color: $tier2color1gr;
        margin-top: 30px;
      }
      &:nth-of-type(2) {
        background-color: $tier3color1gr;
      }
    }
    .carousel-controls {
      bottom: 180px;
    }
    #terms-questions {
      text-align: center;
    }
    .product__footer {
      text-align: center;
      .product_content_fav_mobile {
        height: 23px;
        .product__link-to-default-spp {
          border: 2px solid $color--black;
          padding: 4px;
        }
      }
    }
  }
  &.section-maclover-terms-conditions {
    #intro {
      margin-top: 1.5em;
      font-weight: bold;
    }
    ol {
      counter-reset: item;
    }
    li {
      display: block;
    }
    li:before {
      content: counters(item, '.') '.';
      counter-increment: item;
      position: absolute;
    }
    li.level1:before {
      font-size: 2.3rem;
    }
    li > h4 {
      display: inline-block;
      font-weight: bold;
      color: $color--black;
      margin-#{$ldirection}: 1.5em;
    }
    li span {
      margin-#{$ldirection}: 2.5em;
      display: inline-block;
    }
    ol > li {
      margin-bottom: 2em;
      margin-#{$ldirection}: 1em;
    }
    .level1 > ol,
    .level2 > ol {
      margin-#{$ldirection}: 1em;
    }
    .level2,
    .level3 {
      margin-bottom: 1em;
    }
    .level3 {
      margin-#{$ldirection}: 2em;
    }
  }
  &.page-product {
    &-loyaltya,
    &-loyaltyb,
    &-loyaltyc {
      .product--full .product__add-to-faves {
        display: none;
      }
    }
  }
  .loyalty-samples-panel {
    .loyalty-products {
      .product__description {
        text-align: center;
        .grid-item--1-3-of-4 {
          width: 100%;
        }
      }
      .product__select {
        text-align: center;
      }
    }
  }
  .samples-panel__content {
    .loyalty-products {
      header {
        text-align: center;
        h3 {
          font-family: $roboto-condensed-bold-font;
          font-size: 2.5rem;
          color: $color--white;
          padding: 12px 20px;
        }
      }
      &:nth-of-type(1) {
        header h3 {
          background-color: $tier1color1gr;
        }
      }
      &:nth-of-type(2) {
        header h3 {
          background-color: $tier2color1gr;
        }
      }
      &:nth-of-type(3) {
        header h3 {
          background-color: $tier3color1gr;
        }
      }
    }
  }
}

.mac-select-loyalty-footer-formatter {
  padding: 0;
  li {
    margin: 10px 0;
  }
}
