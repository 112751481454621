.site-container {
  .store_landing_only {
    .tout-block-landscape {
      footer {
        .tout-block-landscape__cta {
          // 20px around the single line CTA (i291901)
          // Counters the btn--fixed_width class
          width: auto;
        }
      }
    }
  }
  .email-customer-service-form {
    #questions {
      .product-tips {
        .picker {
          &:nth-child(3n + 0) {
            display: none;
          }
        }
      }
    }
  }
  .lpPoweredBy {
    display: none;
  }
}

.site-container {
  .mac-artist-content {
    @include breakpoint($bp--xlarge-up $bp--xxlarge-up) {
      .tout-block-landscape__caption {
        .tout-block-landscape__body {
          padding-top: 6px;
          p {
            padding-top: 2px;
            margin-bottom: 0px;
          }
        }
        .tout-block-landscape__headline {
          margin-bottom: 0;
        }
      }
    }
  }
  .store-locator {
    .form--search--advanced {
      height: 200px;
      .form--search {
        .form--inline {
          .form-type-textfield {
            width: 228px;
            padding-bottom: 10px;
          }
          .form-type-countryfield {
            width: 200px;
            text-align: left;
            .country_container {
              .select2-choice {
                height: 59px;
              }
            }
          }
        }
      }
      .geo_container {
        bottom: 80px;
        left: 51%;
      }
    }
    &.store-locator-results {
      .form--search--advanced {
        .form--search {
          width: auto;
          .form--inline {
            width: auto;
            .form-type-countryfield {
              float: left;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}

.culture-detail__article-content__meta {
  .culture__social-links {
    height: 20px;
  }
}

.culture-landing {
  .culture-landing__item {
    // Allow for 6 lines of header and content on 1024x768/ipads
    .culture-landing__teaser-title {
      width: 100%;
    }
    .culture-landing__teaser-wrap {
      height: 270px;
    }
  }
}

.tiny-waitlist-overlay {
  h2.waitlist_header {
    font-size: 18px !important;
  }
  p.waitlist_message {
    line-height: 16px !important;
  }
  ul.error.error_messages {
    li {
      font-size: 10px !important;
    }
  }
  .field {
    .align-r {
      .input-btn {
        width: 80px !important;
      }
    }
    .email_promo {
      padding-top: 5px !important;
      line-height: 12px;
      font-size: 11px;
      input[type='checkbox'] {
        float: left;
        margin: 0 5px 10px 0;
      }
    }
  }
}

.section-artistry-mac-artist-beatriz-cisneros {
  .site-header {
    height: 120px;
  }
}

@include breakpoint($bp--xlarge-down) {
  .site-header {
    li.site-navigation__item.is-dropdown {
      .site-navigation__dropdown {
        .site-navigation__submenu-col {
          padding: 0 10px 0 0px;
        }
      }
    }
  }
}

// Increase the button size to allow longer copy
// Artistry video page
.artistry-video-player__products {
  .video-player__product-slide {
    .product--teaser--tiny {
      .product__add-to-bag,
      .product__link-to-spp {
        height: auto;
        line-height: normal;
        padding: 5px 0px 5px 0px;
      }
    }
  }
}

.site-container {
  // Artistry artist page favourite products
  // (Note, this is overly specific to override a style in emea_base)
  .artistry--artist {
    .artist--faves {
      .artist--products {
        .artist--products__carousel {
          .product--teaser--mini {
            .product__footer {
              .product__add-to-bag,
              .product__link-to-spp,
              .notify_me {
                font-size: 14px;
                padding: 0 5px;
                @include breakpoint($bp--large-down) {
                  // Make full width in this breakpoint as it doesn't need to leave space for the price
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    .shades {
      .product--teaser {
        .product__detail {
          .product__footer {
            .product__add-to-bag,
            .product__link-to-spp,
            .notify_me {
              // Decrease the padding
              padding: 0 5px;
              @include breakpoint($bp--large-up) {
                // Allow smaller screens to stay at 120px to fit the text
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint($bp--large-down) {
  .order-details-page {
    .order-details-page__content {
      .order-products {
        .product-header.mobile_hidden {
          display: block !important;
        }
        .cart-item {
          .cart-item__price.mobile_hidden {
            display: block !important;
          }
        }
      }
    }
  }
}

@include breakpoint($bp--xlarge-down) {
  .section-viva-glam-mac-aids-fund {
    .multi-use-tout {
      display: block;
    }
  }
}

.footer-menu--pc.grid-container--5 {
  .grid--item:nth-of-type(5n + 1) {
    margin-left: 3%;
  }
  .grid--item:nth-of-type(5n + 3) {
    margin-left: 43%;
  }
}

// Enable the TOS message in various places
.grid--mpp__item {
  .product--teaser {
    .product__footer {
      .product__inventory-status {
        .temp-out-of-stock-short__text {
          display: block;
          margin-top: 40px;
          position: absolute;
          #{$rdirection}: 0;
        }
      }
    }
    &.product--shaded {
      &.prod_inv_status-2 {
        &.product--teaser--mini {
          &.product--sku-product {
            .product {
              &__footer {
                .product_content_fav_mobile {
                  .product {
                    &__link-to-spp {
                      display: none;
                    }
                  }
                }
                .product {
                  &__inv-status-item {
                    &.temp-out-of-stock {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.grid--mpp__item {
  .product--not-shoppable.prod_inv_status-5 {
    .product__footer {
      .product__inventory-status {
        .inactive {
          display: block;
        }
      }
    }
  }
}
// Adjust SPP shade picker button to allow for Greek and the wider roboto font.
.view-all-shades--desktop {
  // Only apply for the filter at the top of the shade picker (xlarge/1024 to xxxlarge/1440)
  @include breakpoint($width-large $width-xxxlarge - 1) {
    // Force a line break before the count
    #sku_count::before {
      content: '\a';
      white-space: pre;
    }
    // Adjust styling to allow for two lines of text
    padding-top: 12px;
  }
}

// Adjust the padding on the SPP add to bag bar button to fit longer copy (just for Greek)
.page--spp__product {
  .product__bag-bar {
    .add-to-bag-bar__add-button {
      button {
        .i18n-el-e-GR & {
          padding: 0 5px;
        }
      }
    }
  }
}

.site-footer--bottom {
  // Standard font size of 15px
  @include fontsize-rem(15px);
  ul.menu {
    // Expand to full width of the container
    width: 100%;
  }
  ul.menu > li {
    // Decrease padding to avoid wrapping (in English)
    padding: 0 0.4em 0 0.3em;
  }
}

body.i18n-el-e-GR {
  @include breakpoint($bp--xlarge-down) {
    .grid--mpp {
      .product.product--teaser {
        a.product__link-to-spp,
        a.product__add-to-bag {
          padding: 0 5px;
        }
      }
    }
  }
}

@include breakpoint($bp--large-down) {
  .site-container {
    .grid--mpp__item {
      .product__header {
        .product__rating {
          width: 30%;
          text-align: right;
        }
      }
      .product--teaser {
        .product__add-to-faves {
          position: relative;
          top: 0em;
        }
        .product__header {
          padding-bottom: 320px;
        }
      }
    }
  }
}

@include breakpoint($bp--large-down) {
  .grid--mpp__item {
    .product--teaser {
      .product__footer {
        .product__inventory-status {
          a.notify_me,
          li {
            width: auto;
            position: static;
            line-height: 30px;
            font-size: 15px;
            padding: 0 15px;
            height: 30px;
          }
          .temp-out-of-stock-short__text {
            margin-top: 10px;
            left: 0 !important;
            right: auto !important;
          }
        }
      }
    }
  }
}

#colorbox {
  #cboxWrapper {
    #cboxContent {
      #cboxLoadedContent {
        .waitlist-iframe-wrapper {
          height: 97% !important;
          margin: 0px 7px 0px 7px !important;
        }
      }
    }
  }
}

// Expand the GNAV SMS newsletter optin checkbox label to full width
.site-header .site-header__tools .site-email-signup__contents-form .form-signup-sms-promotions {
  .picker-label {
    width: auto;
  }
}

.site-container {
  .collection-detail-formatter {
    .prod_inv_status-2 {
      .product__footer {
        a.js-notify-me.notify_me {
          width: auto !important;
          top: 0 !important;
          line-height: 30px !important;
        }
      }
    }
  }
}

.page-products.device-pc {
  .grid--mpp__item {
    .product--teaser {
      .product__footer {
        .product_content_fav_mobile {
          min-height: 36px;
          .product__price {
            line-height: 18px;
            max-width: 90px;
          }
          .product__inventory-status {
            padding: 0px;
          }
          ul.product__inventory-status.temp-out-of-stock_notifyme-spacing {
            float: right;
          }
        }
      }
    }
  }
}
