#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'Ναί(';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'Οχι(';
              }
            }
          }
          .pr-rd-flag-review-container {
            margin-right: 5px;
            line-height: 1.5;
          }
        }
      }
    }
  }
}

.product__footer {
  .pr-snippet-stars-reco-inline {
    .pr-snippet-stars-reco-stars {
      .pr-snippet-read-and-write {
        .pr-snippet-review-count {
          display: table;
          margin: 0 0 8px 0;
          &:before {
            content: 'Διαβάστε και τις ';
          }
        }
      }
      .pr-snippet-stars-container {
        &:before {
          content: 'Συνολική: ';
        }
      }
    }
  }
}
