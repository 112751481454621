@if $password_strengthen {
  .profile-info {
    &__change_pwd_link {
      @include breakpoint($bp--medium-up) {
        text-align: #{$rdirection};
        margin-#{$rdirection}: 29%;
      }
    }
  }
  .sign-in-container {
    .new-account {
      .password-field {
        &__info {
          @include breakpoint($bp--medium-up) {
            bottom: 30%;
          }
        }
      }
    }
  }
}
