.site-container {
  .checkout__sidebar {
    .checkout__panel-content {
      .product__desc {
        .add-to-cart {
          .btn {
            // Allow for longer copy on the "add to cart" button
            font-size: 11px;
            @include swap_direction(padding, 0px 10px);
          }
        }
      }
    }
    .viewcart-header {
      h2 {
        // Longer copy on the "Shopping bag" link
        width: 70%;
      }
    }
  }
  .loyalty-samples-panel {
    .checkout-header {
      text-align: center;
    }
    .samples-grid {
      .sample-select-button {
        width: 145px;
      }
    }
  }
}

// Spacing in the payment option fields
.checkout__content {
  .shipping-panel {
    .select-address.form-container {
      #google_locationField {
        margin-bottom: 15px;
      }
    }
  }
  .review-panel {
    #google_locationField {
      margin-bottom: 15px;
    }
    .installopts {
      margin-bottom: 15px;
    }
    .payment-description {
      margin-bottom: 15px;
      &.payment-description-PAYMENT_OPTION_COD {
        // Not the COD as it has no text, leaving a double gap
        margin-bottom: 0px;
      }
    }
    .picker-checkbox {
      @include breakpoint($bp--medium) {
        padding-bottom: 7%;
      }
    }
  }
  .sign-in-panel {
    .signin-legal {
      .info-link {
        width: 49%;
      }
    }
  }
}

@include breakpoint($bp--xlarge-down) {
  .checkout__content {
    .order-summary__content {
      .value {
        width: 18.33333%;
        margin-left: 75.33333%;
      }
    }
  }
}

.checkout-header {
  .remaining {
    .available {
      margin-right: 157px;
    }
  }
}
